import React, { FC } from "react";
import styled from "styled-components";
import { mui } from "@intouchhealth/cig-components";
import { FlexRowCenteredStyle } from "@intouchhealth/mfe-library";
import { FlowContextMenuButton } from "../flowworkspace/FlowContextMenuButton";

export const FlowGroupTitleBar: FC<{
  id: string;
  title: string;
  titleClickHandler: () => void;
  menuClickHandler: (event: React.MouseEvent) => void;
}> = ({ id, title, titleClickHandler, menuClickHandler }) => {
  const { Tooltip } = mui;
  return (
    <>
      <FlexRowCenteredStyle style={{ marginTop: "-5px", marginBottom: "-5px" }}>
        <Tooltip title={title} enterDelay={750} placement={"right"}>
          <TitleContainer
            id={"navtitle-" + id}
            onClick={() => titleClickHandler()}
          >
            {title}
          </TitleContainer>
        </Tooltip>
        <FlowContextMenuButton
          menuClickHandler={(event) => {
            menuClickHandler(event);
          }}
        ></FlowContextMenuButton>
      </FlexRowCenteredStyle>
    </>
  );
};

const TitleContainer = styled.div`
  max-width: calc(100% - 16px);
  min-width: calc(100% - 16px);
  color: #1976d2;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border: solid 1px transparent;
  :hover {
    color: #1986e2;
    border: dotted 1px gray;
  }
`;
