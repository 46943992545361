export const FlowWorkspaceStrings = {
  FirstMessageLabel: "First Message",
  FirstMessageHelpText:
    "The patient's answer to this question will be sent to the clinician as the initial message of the messaging visit.",
  MetaDataLabel: "Meta Data",
  MetaDataHelpText: "Help Text for Meta Data",
  PageBranchingFromLabel: "Branching from:",
  PageTitleLabel: "Page title",
  PageReadOnlyTitleLabel:
    "A read only title shown only in the designer for complex page types.",
  PageTitleRequiredText: "Page title is required",
  PageTitleHelpText: "1-4 words is ideal",
  ReferenceCategoryLabel: "Reference tag",
  ReferenceCategoryPageHelpText:
    "Allows other applications to retrieve patient responses for this page.",
  ReferenceCategoryQuestionHelpText:
    "Allows other applications to retrieve patient responses for this question.",
  ReferenceCategoryAnswerHelpText:
    "Allows other applications to retrieve patient responses for this answer.",
  ReferenceCategoryElementTagNotAvailable:
    "NOTE: Reference tags for the page and question are the same when there is only one element on a page. Editing this tag will also modify the tag for the page.",
  ContinueTextLabel: "Navigation button label",
  ContinueTextDefault: "Continue",
  ContinueTextRequiredText: "Navigation button label is required",
  ContinueTextHelpText: "Leads patient to next page or end screen",
  AddBranchPageButton: "Add page to branch",
  AddElementButton: "Add question",
  RequiredElementCheckboxLabel: "Required",
  NoAddElementToBranchingDialogTitle: "Adding Elements Not Allowed",
  NoAddElementToBranchingDialogText:
    "Additional elements may not be added to a page that contains a Question Tree element.",
  NoAddElementToBranchingCloseButtonText: "Ok",
  QuestionTreeNeedsOwnPageTitle: "Add Question Tree",
  QuestionTreeNeedsOwnPageText: "Each question tree requires a new page.",
  QuestionTreeNeedsOwnPageConfirmText: "Add a new page",
  QuestionTreeNeedsOwnPageCancelText: "Cancel",
  NoSecondFileUploadDialogTitle: "Second File Upload Question Not Allowed",
  NoSecondFileUploadDialogCloseButtonText: "OK",
  NoSecondFileUploadDialogText:
    "Only one file upload per page is allowed.  Create another page to allow file uploads for another question.",
  ElementNeedsOwnPageTitle: "Question will appear on a new page",
  ElementNeedsOwnPageText:
    "This type of question includes multiple fields, and works best on its own page.",
  ElementNeedsOwnPageConfirmText: "OK",
  ElementNeedsOwnPageCancelText: "Cancel",
};
