import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import {
  hasValue,
  PageContainer,
  VerticalSpacer,
} from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../../app/stores/rootStore";
import { InitialDefaultLanguage } from "../../app/stores/translationOptionsStore";
import DetailsSection, { DetailsSectionContext } from "./DetailsSection";
import FlowSection, { FlowSectionContext } from "./FlowSection";
import { ImportTemplateProvider } from "./ImportTemplateProvider";
import { TemplateFormButtons } from "./TemplateFormButtons";
import { DynamicTemplateType } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { cloneDeep } from "@apollo/client/utilities";

const ImportTemplateLayout: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const detailsContext = useContext(DetailsSectionContext);
  const flowContext = useContext(FlowSectionContext);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const dynamicTemplate = {
    organizationId: commonStore.organizationId,
    practiceId: commonStore.practiceId,
    defaultLanguageCode: InitialDefaultLanguage,
  } as DynamicTemplateType;

  const isChanged = () => {
    var upatedTemplate = cloneDeep(dynamicTemplate);
    detailsContext.updateFields(upatedTemplate);
    flowContext.updateFields(upatedTemplate);
    if (
      hasValue(upatedTemplate.name) ||
      hasValue(upatedTemplate.type) ||
      hasValue(upatedTemplate.endAction) ||
      hasValue(upatedTemplate.flowJson ?? "")
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <PageContainer>
        <DetailsSection
          isCreate={true}
          isImport={true}
          dynamicTemplate={dynamicTemplate}
        />
        <VerticalSpacer />
        <FlowSection />
        <VerticalSpacer />
        <TemplateFormButtons
          mode={"CREATE"}
          isChanged={isChanged}
          setIsUpdated={setIsUpdated}
        />
      </PageContainer>
      <ImportTemplateProvider
        dynamicTemplate={dynamicTemplate}
        updated={isUpdated}
        setUpdated={setIsUpdated}
        onCreated={() => {
          commonStore.history.push(`${commonStore.rootPath()}?notify=created`);
        }}
      ></ImportTemplateProvider>
    </>
  );
};

const ImportTemplateForm: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);

  useEffect(() => {
    commonStore.setBreadCrumbs([
      {
        text: TemplateFormStrings.DynamicTemplatesBreadCrumb,
        link: "./dynamic-template",
        icon: undefined,
      },
    ]);
    commonStore.setHeader(TemplateFormStrings.PageHeaderImportJson);
  });

  return <ImportTemplateLayout />;
};

export default observer(ImportTemplateForm);
