import { PureInput } from "@intouchhealth/cig-components";
import { useFormDialog } from "@intouchhealth/mfe-library";
import { useContext, useState } from "react";
import {
  getElement,
  getPage,
  IAnswer,
  IElementDesign,
  IPageDesign,
} from "../../app/stores/templateDesignStore";
import { FlowWorkspaceStrings } from "../flowworkspace/FlowWorkspaceStrings";
import { TemplateFormStrings } from "../templatesform/TemplateFormStrings";
import { FlowNavigatorContext } from "./FlowNavigator";
import styled from "styled-components";

type refCatInputTypes = "ELEMENT" | "ANSWER" | "PAGE";

export const useReferenceCategoryHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const [page, setPage] = useState<IPageDesign | undefined>(undefined);
  const [element, setElement] = useState<IElementDesign | undefined>(undefined);
  const [answer, setAnswer] = useState<IAnswer | undefined>(undefined);
  const [inputType, setInputType] = useState<refCatInputTypes>("PAGE");
  const { ...form } = useFormDialog(
    TemplateFormStrings.ReferenceCategoryDialogTitle,
    TemplateFormStrings.OkButtonLabel,
    TemplateFormStrings.CancelButtonLabel,
  );

  const ReferenceCategoryDialog: React.VFC = () => {
    const [referenceCategory, setReferenceCategory] = useState<string>(
      (element ?? answer ?? page)?.referenceCategory ?? "",
    );

    const handleAccepted = () => {
      if (page) {
        (element ?? answer ?? page).referenceCategory = referenceCategory;
      }
      form.setIsOpen(false);
    };

    const handleCancelled = () => {
      form.setIsOpen(false);
    };

    context.referenceCategoryHandler = (
      pageId: string | undefined = undefined,
      elementId: string | undefined = undefined,
      answer: IAnswer | undefined = undefined,
    ) => {
      let page;
      if (pageId) {
        page = getPage(context.section(), pageId);
      }
      setPage(page);

      let element;
      if ((page?.subpages.length ?? 0) > 1 && elementId) {
        element = getElement(page, elementId);
      }
      setElement(element);

      if (answer) {
        setAnswer(answer);
      }

      if (elementId) {
        setInputType("ELEMENT");
      } else if (answer) {
        setInputType("ANSWER");
      } else {
        setInputType("PAGE");
      }

      form.setIsOpen(true);
    };

    return (
      <form.FormDialog
        position="appcontainer"
        top={40}
        width={"75%"}
        onAccepted={handleAccepted}
        onCancelled={handleCancelled}
        acceptDisabled={false}
      >
        <PureInput
          label={FlowWorkspaceStrings.ReferenceCategoryLabel}
          value={referenceCategory}
          onChange={(value) => {
            setReferenceCategory(value);
          }}
          className="sls-input"
          infoMessage={
            inputType === "ELEMENT"
              ? FlowWorkspaceStrings.ReferenceCategoryQuestionHelpText
              : inputType === "ANSWER"
                ? FlowWorkspaceStrings.ReferenceCategoryAnswerHelpText
                : FlowWorkspaceStrings.ReferenceCategoryPageHelpText
          }
        />
        {inputType === "ELEMENT" && (page?.subpages.length ?? 0) < 2 ? (
          <NotAvailableTextStyle>
            {FlowWorkspaceStrings.ReferenceCategoryElementTagNotAvailable}
          </NotAvailableTextStyle>
        ) : (
          <></>
        )}
      </form.FormDialog>
    );
  };

  return {
    ReferenceCategoryDialog,
  };
};

const NotAvailableTextStyle = styled.div`
  color: #cb220e;
  max-width: 600px;
`;
