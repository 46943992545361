import { useContext, useEffect } from "react";
import { Route, Router, Routes } from "react-router-dom";
import Dashboard from "../../features/dashboard/Dashboard";
import {
  InjectFont,
  Dimmer,
  LoaderIcon,
  colors,
} from "@intouchhealth/cig-components";
import {
  DialogAppContext,
  NotFound,
  useLegacyRouterState,
} from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import { DynamicTemplateRenderParameters } from "../../serviceInterface";
import styled from "styled-components";
import CreateDynamicTemplateOptions from "../../features/templatesform/CreateTemplateOptions";
import ImportTemplateForm from "../../features/templatesform/ImportTemplateForm";
import UpdateTemplateDetailsForm from "../../features/templatesform/UpdateTemplateDetailsForm";
import UpdateTemplateForm from "../../features/templatesform/UpdateTemplateForm";
import CopyTemplateForm from "../../features/templatesform/CopyTemplateForm";
import CreateTemplateForm from "../../features/templatesform/CreateTemplateForm";

const App = (props: DynamicTemplateRenderParameters) => {
  const { routerState } = useLegacyRouterState(props.browserHistory);
  const rootStore = useContext(RootStoreContext);
  const dialogContext = useContext(DialogAppContext);

  const {
    setAppLoaded,
    setAppLocation,
    appLoaded,
    setHistory,
    setOrganizationId,
    setPracticeId,
    InitializeBreadCrumbsSetter,
    InitializeHeaderSetter,
    InitializeIdpTokenFunc,
    setStoreInitialized,
  } = rootStore.commonStore;

  useEffect(() => {
    (async function initializeStore() {
      setStoreInitialized();
      InitializeIdpTokenFunc(props.idpToken);
      setHistory(props.browserHistory);
      setOrganizationId(props.organizationId);
      setPracticeId(props.practiceId);
      InitializeBreadCrumbsSetter(props.setBreadCrumbs);
      InitializeHeaderSetter(props.setHeader);
      InitializeIdpTokenFunc(props.idpToken);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async function appLoaded() {
      setAppLoaded();
      var rootContainer = document
        .getElementById("DynamicTemplate-container")
        ?.parentElement?.getBoundingClientRect();
      var appTop = rootContainer?.top ?? 0;
      var appLeft = rootContainer?.left ?? 0;
      setAppLocation(appTop, appLeft);
      dialogContext.clientTop = appTop;
      dialogContext.clientLeft = appLeft;
    })();
    // eslint-disable-next-line
  }, [setAppLoaded]);

  if (!appLoaded)
    return (
      <Dimmer
        header="Loading app..."
        icon={<LoaderIcon iconSize="xxl" sizing="relative" />}
      />
    );

  return (
    <Container>
      <InjectFont family="Montserrat" />
      <Router
        location={routerState.location}
        navigationType={routerState.action}
        navigator={routerState.history}
      >
        <Routes>
          <Route path={rootStore.commonStore.parentPath()}>
            <Route
              path="dynamic-template/createoptions"
              element={<CreateDynamicTemplateOptions />}
            />
            <Route
              path="dynamic-template/create"
              element={<CreateTemplateForm />}
            />
            <Route
              path="dynamic-template/import"
              element={<ImportTemplateForm />}
            />
            <Route
              path="dynamic-template/design/:templateId"
              element={
                process.env.REACT_APP_DTS_DESIGNER_ENABLED === "TRUE" ? (
                  <UpdateTemplateForm />
                ) : (
                  <UpdateTemplateDetailsForm />
                )
              }
            />
            <Route
              path="dynamic-template/copy/:templateId"
              element={<CopyTemplateForm />}
            />
            <Route path="dynamic-template" element={<Dashboard />} />
            <Route element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  flex-direction: ${isMobile ? "column" : "row"};
  display: flex;
  background-color: #${colors.N100};
`;

export default observer(App);
