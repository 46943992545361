import { Button } from "@intouchhealth/cig-components";
import { useContext, useEffect } from "react";
import styled from "styled-components";
import {
  CancelButton,
  PageContainer,
  PanelContainer,
} from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../../app/stores/rootStore";
import { TemplateFormStrings } from "./TemplateFormStrings";

const CreateDynamicTemplateOptions: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);

  useEffect(() => {
    commonStore.setBreadCrumbs([
      {
        text: TemplateFormStrings.DynamicTemplatesBreadCrumb,
        link: "./dynamic-template",
        icon: undefined,
      },
    ]);
    commonStore.setHeader(TemplateFormStrings.PageHeaderCreate);
  });

  return (
    <PageContainer>
      <PanelContainer alignCenter={true} transparent={true}>
        <ButtonWrapper>
          <Button
            themeName="whitelabel"
            onClick={(): void | Promise<void> => {
              commonStore.history.push(`${commonStore.rootPath()}/create`);
            }}
          >
            {TemplateFormStrings.ButtonStartFromScratchLabel}
          </Button>
          <Button
            themeName="whitelabel"
            onClick={(): void | Promise<void> => {
              commonStore.history.push(`${commonStore.rootPath()}/import`);
            }}
          >
            {TemplateFormStrings.ButtonImportFromJsonLabel}
          </Button>
        </ButtonWrapper>
      </PanelContainer>
      <PanelContainer transparent={true}>
        <CancelButton
          size="xs"
          onClick={(): void | Promise<void> => {
            commonStore.history.push("../dynamic-template");
          }}
        >
          {TemplateFormStrings.CancelButtonLabel}
        </CancelButton>
      </PanelContainer>
    </PageContainer>
  );
};

export default CreateDynamicTemplateOptions;

const ButtonWrapper = styled.div`
  & button {
    background-color: #ffffff;
    padding: 30px;
    width: 100%;
    border: 2px solid #005d9a;
    margin-bottom: 10px;
    color: #0d77aa;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  & button:hover {
    border: 2px solid #005d9a;
    background-color: #c6e5fa;
  }
`;
