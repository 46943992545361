import { createContext, FC, useContext, useEffect, useState } from "react";

import styled from "styled-components";
import {
  getRootPage,
  IAnswer,
  IPageDesign,
  ISectionDesign,
  newTemplatePage,
  newTemplateSection,
} from "../../app/stores/templateDesignStore";
import { NotificationProperty, uniqueName } from "@intouchhealth/mfe-library";
import { FlowMenuControlChoices } from "../flowmenus/FlowMenuControl";
import { useFlowContainer } from "./FlowContainer";
import { useDeletePageHandler } from "./DeletePageHandler";
import { useMovePageHandler } from "./MovePageHandler";
import { useDeleteElementHandler } from "./DeleteElementHandler";
import { useDuplicateElementHandler } from "./DuplicateElementHandler";
import { useMoveElementHandler } from "./MoveElementHandler";
import { useReferenceCategoryHandler } from "./ReferenceCategoryHandler";
import { useMetaDataHandler } from "./MetaDataHandler";

export const flowNavigatorWidth = "250px";
export type FlowNavSelectionTypes = "none" | "page" | "element";
const previewNotification = new NotificationProperty();

export const FlowNavigatorContext = createContext({
  navElementsKey: (): string => {
    return "";
  },
  refreshNavElements: () => {},
  updateNavTitle: (_id: string, _title: string) => {},
  scrollToNavTitle: (_id: string) => {},
  sections: [] as ISectionDesign[],
  section: (): ISectionDesign => {
    return newTemplateSection();
  },
  page: (
    _section: ISectionDesign | undefined,
    _pageId: string,
  ): IPageDesign | undefined => {
    return undefined;
  },
  selectedPageId: "",
  pageIsActive: (_pageId: string): boolean => {
    return false;
  },
  addPageHandler: () => {},
  deletePageHandler: (_pageId: string) => {},
  referenceCategoryHandler: (
    _pageId: string | undefined = undefined,
    _elementId: string | undefined = undefined,
    _answer: IAnswer | undefined = undefined,
  ) => {},
  metaDataHandler: (_answer: IAnswer | undefined = undefined) => {},
  movePageHandler: (_pageId: string, _direction: "up" | "down") => {},
  selectPageHandler: (_pageId: string) => {},
  deleteElementHandler: (_pageId: string, _elementId: string) => {},
  duplicateElementHandler: (_pageId: string, _elementId: string) => {},
  moveElementHandler: (
    _pageId: string,
    _elementId: string,
    _direction: "up" | "down",
  ) => {},
  pageOptionMenuHandler: (
    _pageId: string,
    _menuChoice: FlowMenuControlChoices,
  ) => {},
  elementOptionMenuHandler: (
    _pageId: string,
    _elementId: string,
    _menuChoice: FlowMenuControlChoices,
  ) => {},
  setPreviewTemplateId: (_: string) => {},
  previewNotification: null as NotificationProperty | null,
});

export const useFlowNavigator = () => {
  const [sections, setSections] = useState<ISectionDesign[]>(
    [] as ISectionDesign[],
  );
  const [selectedType, setSelectedType] =
    useState<FlowNavSelectionTypes>("none");
  const [selectedPageId, setSelectedPageId] = useState<string>("");
  const [navElementsKey, setNavElementsKey] = useState(
    uniqueName("nav-elmt-grp"),
  );
  const navigator = useContext(FlowNavigatorContext);
  const { DeletePageDialog } = useDeletePageHandler();
  const { MovePageDialog } = useMovePageHandler();
  const { DeleteElementDialog } = useDeleteElementHandler();
  const { DuplicateElementDialog } = useDuplicateElementHandler();
  const { MoveElementDialog } = useMoveElementHandler();
  const { ReferenceCategoryDialog } = useReferenceCategoryHandler();
  const { MetaDataDialog } = useMetaDataHandler();

  navigator.navElementsKey = () => {
    return navElementsKey;
  };

  navigator.refreshNavElements = () => {
    setNavElementsKey(uniqueName("nav-elmt-grp"));
  };

  navigator.setPreviewTemplateId = (id: string) => {
    previewNotification.publish(id);
  };

  navigator.previewNotification = previewNotification;

  navigator.updateNavTitle = (id: string, title: string) => {
    var navtitle = document.getElementById("navtitle-" + id);
    if (navtitle) {
      navtitle.innerHTML = title;
    }
  };

  navigator.scrollToNavTitle = (id: string) => {
    var navtitle = document.getElementById("navgroup-" + id);
    if (navtitle) {
      navtitle.scrollIntoView({ block: "nearest" } as ScrollIntoViewOptions);
    }
  };

  navigator.sections = sections;
  navigator.section = (): ISectionDesign => {
    return sections.length > 0 ? sections[0] : ({} as ISectionDesign);
  };

  navigator.page = (
    section: ISectionDesign | undefined,
    pageId: string,
  ): IPageDesign | undefined => {
    return section?.pages.find((p) => p.id === pageId);
  };

  navigator.selectedPageId = selectedPageId;
  navigator.pageIsActive = (pageId: string) => {
    if (selectedPageId?.length > 0) {
      if (pageId === selectedPageId) {
        return true;
      }

      if (getRootPage(sections[0], selectedPageId)?.id === pageId) {
        return true;
      }
    }
    return false;
  };

  navigator.selectPageHandler = (pageId: string) => {
    setSelectedPageId(pageId);
    setSelectedType("page");
  };

  navigator.addPageHandler = () => {
    var section = navigator.sections[0];
    if (section) {
      var newPage = newTemplatePage();
      section.pages.push(newPage);
      navigator.selectPageHandler(newPage.id);
    }
  };

  const { ...flow } = useFlowContainer(sections[0]);

  const NavigatorPanel: FC = () => {
    return (
      <>
        <StyledContainer>
          <flow.FlowContainer />
        </StyledContainer>
        <DeletePageDialog />
        <MovePageDialog />
        <DeleteElementDialog />
        <DuplicateElementDialog />
        <MoveElementDialog />
        <ReferenceCategoryDialog />
        <MetaDataDialog />
      </>
    );
  };

  useEffect(() => {
    if (sections.length > 0) {
      var pageId = sections[0].pages.length > 0 ? sections[0].pages[0].id : "";
      setSelectedPageId(pageId);
      if (pageId !== "") {
        setSelectedType("page");
      } else {
        setSelectedType("none");
      }
    }
  }, [sections]);

  return {
    NavigatorPanel,
    sections,
    setSections,
    selectedType,
    selectedPageId,
    selectPage: navigator.selectPageHandler,
  };
};

const StyledContainer = styled.div`
  height: calc(100vh - 130px);
  border-right: solid #717171;
`;
