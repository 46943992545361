import * as serviceWorker from "./serviceWorker";
import App from "./app/layout/App";
import ReactDOM from "react-dom/client";
import { DynamicTemplateInterface } from "./serviceInterface";

let currentRoot: ReactDOM.Root | null = null;

//@ts-ignore
window.renderDynamicTemplate = (props: DynamicTemplateInterface) => {
  const root = ReactDOM.createRoot(
    document.getElementById(props.containerName) as HTMLElement,
  );
  currentRoot = root;
  root.render(
    <div>
      <App
        organizationId={props.organizationId}
        practiceId={props.practiceId}
        browserHistory={props.browserHistory}
        setBreadCrumbs={props.setBreadCrumbs}
        setHeader={props.setHeader}
        idpToken={props.idpToken}
      />
    </div>,
  );
  serviceWorker.unregister();

  //@ts-ignore
  window.renderedDynamicTemplateFrontend = true;
};

//@ts-ignore
window.unmountDynamicTemplate = () => {
  //@ts-ignore
  if (currentRoot) {
    currentRoot.unmount();
  }
};
